import { Observable, of } from 'rxjs';
import { IdentityRepository, IdentityUser, Menu } from '@model/identity';
import { IdentityOperations } from '@model/identity/operations';

/**
 * Implementación de repositorio de identidad para pruebas
 */
export class IdentityRepositoryImplMock extends IdentityRepository {
  user: IdentityUser = {
    userName: 'Jhon Doe',
    fullName: 'Jhon Doe',
    lastAccess: '2021-09-01',
    urlLogout: '',
    fullUrlLogout: '',
    menu: JSON.stringify({
      name: 'Menu',
      menuItems: [
        {
          cdresource: 1,
          contextPath: '/professionals',
          name: 'Profesionales',
          image: 'image1',
          order: 1,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        },
        {
          cdresource: 2,
          contextPath: '/shifts',
          name: 'Turnos',
          image: 'image2',
          order: 2,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        },
        {
          cdresource: 3,
          contextPath: '/schedule',
          name: 'Programación',
          image: 'image2',
          order: 2,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        },
        {
          cdresource: 3,
          contextPath: '/schedule/professional',
          name: 'Agenda del profesional',
          image: 'image2',
          order: 4,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        }
      ]
    })
  };

  /**
   * Retorna la información del usuario
   * @returns usuario mockeado
   */
  override getUserInfo(): Observable<IdentityUser> {
    return of(this.user);
  }

  /**
   * Retorna el menú del usuario
   * @returns menú del usuario mockeado
   */
  override getUserMenu(): Promise<Menu[]> {
    return Promise.resolve(IdentityOperations.getMenu(this.user));
  }

  /**
   * Realiza el logout del usuario
   * @param fullUrlLogout url de logout
   */
  override fullLogout(fullUrlLogout: string): void {
    console.log('logged out!', fullUrlLogout);
  }
}
