import { Routes } from '@angular/router';
import { SecurityLoaders } from '@cfg/security/loaders';

/**
 * Rutas de la aplicación
 */
export const APP_ROUTES: Routes = [
  {
    path: 'shifts',
    loadChildren: () =>
      import('./shifts/shifts.routes').then((s) => s.shiftRoutes),
    canActivate: SecurityLoaders.loadGuards()
  },
  {
    path: 'professionals',
    loadChildren: () =>
      import('./professionals/professionals.routes').then(
        (p) => p.professionalRoutes
      ),
    canActivate: SecurityLoaders.loadGuards()
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('./schedule/schedule.routes').then((p) => p.scheduleRoutes),
    canActivate: SecurityLoaders.loadGuards()
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./shared/pages/access-denied/access-denied.component').then(
        (a) => a.AccessDeniedComponent
      )
  }
];
