import { Observable } from 'rxjs';
import { IdentityUser } from '../user';
import { Menu } from '../menu';

/**
 * Define el repositorio para obtener información del usuario en sesión
 */
export abstract class IdentityRepository {
  /**
   * Obtiene la información del usuario en sesión
   */
  abstract getUserInfo(): Observable<IdentityUser>;

  /**
   * Obtiene el menú del usuario en sesión
   */
  abstract getUserMenu(): Promise<Menu[]>;

  /**
   * Verifica si el usuario está logueado
   */
  abstract fullLogout(fullUrlLogout: string): void;
}
