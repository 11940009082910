import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, provideZoneChangeDetection } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { primeConfig } from '@cfg/design/prime.config';
import { initializePrimeTranslation } from '@cfg/i18n/initializer';
import { translationConfig } from '@cfg/i18n/translation.config';
import { SecurityLoaders } from '@cfg/security/loaders';

import { AppComponent } from '@view/app.component';
import { APP_ROUTES } from '@view/routes';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    provideHttpClient(withInterceptors(SecurityLoaders.loadInterceptors())),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    ...primeConfig,
    ...translationConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializePrimeTranslation,
      multi: true
    }
  ]
}).catch((err) => console.error(err));
