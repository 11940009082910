import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpInterceptorFn
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { RouteService } from '@helpers/routing/router.service';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
export const securityInterceptRequestService: HttpInterceptorFn = (
  req,
  next
) => {
  const router = inject(RouteService);
  const location = inject(Location);

  let headers = new HttpHeaders({ 'X-APP-RELAYSTATE': location.path() });

  // Verificar si el cuerpo de la solicitud es FormData
  if (!(req.body instanceof FormData)) {
    // Si no es FormData, usa 'application/json'
    headers = headers.append('Content-Type', 'application/json');
  }

  const reqWithHeaders = req.clone({ withCredentials: true, headers });

  return next(reqWithHeaders).pipe(
    catchError((err: HttpErrorResponse) => {
      switch (err.status) {
        case 401:
          //  Presenta la pagina de login desde seus
          router.replaceWith(err.error as string);
          return throwError(() => err);
        case 403:
          router.navigateTo('/access-denied');
          return throwError(() => err);
        default:
          return throwError(() => err);
      }
    })
  );
};
