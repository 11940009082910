import { environment } from 'src/environments/environment';

/**
 * Comprueba si la seguridad esta en modo mock
 * @returns {boolean} true si la aplicación está en modo mock
 */
const isSecureMock = () => environment.identity.useMocks;

/**
 * Exporta las utilidades para manejo del ambiente
 */
export const EnvUtils = {
  isSecureMock
};
