import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { IdentityServiceImpl } from './identity.service';

/**
 * Guard para autorizar acceso a las rutas de la aplicación
 * @param route ActivatedRouteSnapshot
 * @param state RouterStateSnapshot
 * @returns true si el usuario está autorizado, false en caso contrario
 */
export const AuthorizationGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const seusService = inject(IdentityServiceImpl);
  const router = inject(Router);

  const doActivate = (state: RouterStateSnapshot): Promise<boolean> => {
    const _url = state.url.split('?')[0];
    return new Promise((resolver) => {
      if (!seusService.isLoggedIn()) {
        seusService.getLoginForm().subscribe({
          next: (form: string) => {
            if (form === 'LoggedInYet') {
              checkAuthorization(_url, resolver);
            } else {
              document.write(form);
            }
          },
          error: (error) => console.error(error)
        });
      }

      checkAuthorization(_url.substring(1), resolver);
    });
  };

  const checkAuthorization = (
    url: string,
    resolver: (param: boolean) => void
  ): void => {
    seusService.isAuthorized(url).subscribe({
      next: (res: boolean) => {
        if (!res) {
          router.navigate(['access-denied']).catch((err) => console.error(err));
        }
        resolver(res);
      },
      error: (err) => {
        router.navigate(['access-denied']).catch((err) => console.error(err));
        console.error(err);
        resolver(false);
      }
    });
  };

  return doActivate(state);
};
