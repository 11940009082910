import { PrimeNotificationService } from '@adapter/notification/prime-notification.service';
import { AppNotificationGateway } from '@service/notification/app-notification.gateway';
import { AppNotificationService } from '@service/notification/app-notification.service';
import { MessageService } from 'primeng/api';

/**
 * Proveedor del gateway de notificaciones
 */
const getNotificationServiceProvider = {
  provide: AppNotificationGateway,
  useClass: PrimeNotificationService
};

/**
 * Factoria para el servicio de notificaciones
 * @param gateway gateway de notificaciones
 * @returns implementación del servicio
 */
const getNotificationServiceFactory = (gateway: AppNotificationGateway) => {
  return new AppNotificationService(gateway);
};

/**
 * Proveedor del servicio de notificaciones
 */
const getNotificationService = {
  provide: AppNotificationService,
  useFactory: getNotificationServiceFactory,
  deps: [AppNotificationGateway]
};

/**
 * Proveedor del servicio de mensajes de prime
 */
export const getPrimeMessageService = {
  provide: MessageService,
  useClass: MessageService
};

/**
 * DI de notificaciones
 */
export const getNotificationDi = [
  getPrimeMessageService,
  getNotificationServiceProvider,
  getNotificationService
];
