import { inject, Injectable } from '@angular/core';
import { AppConfirmation } from '@service/confirmation/confirmation';
import { AppConfirmationGateway } from '@service/confirmation/app-confirmation.gateway';
import { ConfirmationService } from 'primeng/api';

/**
 * Implementación del servicio de confirmación usando la primeNG
 */
@Injectable({
  providedIn: 'root'
})
export class PrimeConfirmationService extends AppConfirmationGateway {
  /**
   * Servicio de confirmación de primeNG
   */
  private primeConfirmationService = inject(ConfirmationService);

  /**
   * Confirmación de una acción
   * @param confirmation información de la confirmación
   * @returns {Promise<boolean>} confirmación de la acción
   */
  confirm(confirmation: AppConfirmation): Promise<boolean> {
    return new Promise((resolve) => {
      this.primeConfirmationService.confirm({
        header: confirmation.title,
        message: confirmation.message,
        icon: confirmation.icon,
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => resolve(true),
        reject: () => resolve(false)
      });
    });
  }
}
