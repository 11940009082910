import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Funcion que carga las traducciones de los ficheros JSON.
 * @param http cliente HTTP para realizar la petición que descarga el fichero JSON.
 * @returns Loader para las traducciones.
 */
export const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (
  http: HttpClient
) => new TranslateHttpLoader(http, '/assets/i18n/', '.json');
