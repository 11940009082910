import { PrimeConfirmationService } from '@adapter/confirmation/app-confirmation.service';
import { AppConfirmationGateway } from '@service/confirmation/app-confirmation.gateway';
import { AppConfirmationService } from '@service/confirmation/app-confirmation.service';
import { ConfirmationService } from 'primeng/api';

/**
 * Proveedor del gateway de confirmación
 */
const getConfirmationServiceProvider = {
  provide: AppConfirmationGateway,
  useClass: PrimeConfirmationService
};

/**
 * Factoria para el servicio de confirmación
 * @param gateway gateway de confirmación
 * @returns implementación del servicio
 */
const getConfirmationServiceFactory = (gateway: AppConfirmationGateway) => {
  return new AppConfirmationService(gateway);
};

/**
 * Proveedor del servicio de confirmación
 */
const getConfirmationService = {
  provide: AppConfirmationService,
  useFactory: getConfirmationServiceFactory,
  deps: [AppConfirmationGateway]
};

/**
 * Proveedor del servicio de confirmación de primeng
 */
const getPrimeConfirmationService = {
  provide: ConfirmationService,
  useClass: ConfirmationService
};

/**
 * DI de confirmación
 */
export const getConfirmationDi = [
  getPrimeConfirmationService,
  getConfirmationServiceProvider,
  getConfirmationService
];
