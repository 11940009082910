import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  input,
  OnInit,
  Output,
  runInInjectionContext
} from '@angular/core';
import { OptionItem } from '../../molecules/option-item/option-item';
import { OptionItemComponent } from '@view/shared/molecules/option-item/option-item.component';

/**
 * Componente que representa una lista de opciones.
 */
@Component({
  selector: 'app-option-list',
  imports: [CommonModule, OptionItemComponent],
  template: `
    <ul>
      @for (item of _items; track $index) {
        <li
          tabindex="0"
          class="my-2 cursor-pointer py-2 app-anim-focus"
          [ngClass]="{ 'bg-blue-500 text-white': item.active && selectable }"
          (click)="onItemClick(item)"
          (keydown.enter)="onItemClick(item)"
        >
          <app-option-item [item]="item" iconPosition="left"></app-option-item>
        </li>
      }
    </ul>
  `,
  styles: `
    ul {
      list-style-type: none;
      padding: 0;
    }
  `
})
export class OptionListComponent implements OnInit {
  /** Lista de items. */
  items = input.required<OptionItem[]>();
  /** Indica si los items son seleccionables. */
  selectable = input(false);
  /** Emite el evento de click sobre un item. */
  @Output() itemClick = new EventEmitter<OptionItem>();

  injector = inject(Injector);

  _items: OptionItem[] = [];

  ngOnInit(): void {
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this._items = this.items();
      });
    });
  }

  /**
   * Maneja el evento de click sobre un item.
   * @param optionItemSelected item seleccionado.
   */
  onItemClick(optionItemSelected: OptionItem) {
    if (this.selectable()) {
      this._items = this.items().map((item) => {
        return {
          ...item,
          active: item.id === optionItemSelected.id
        };
      });
    }

    this.itemClick.emit(optionItemSelected);
  }
}
