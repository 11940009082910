import { NgxTranslatorService } from '@adapter/i18n/ngx-translator.service';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationGateway } from '@service/translation/app-translation.gateway';
import { AppTranslationService } from '@service/translation/app-translation.service';

/**
 * Proveedor del gateway de traducción
 */
const getTranslationServiceProvider = {
  provide: AppTranslationGateway,
  useClass: NgxTranslatorService
};

/**
 * Factoria para el servicio de traducción
 * @param gateway gateway de traducción
 * @returns implementación del servicio
 */
const getTranslationServiceFactory = (gateway: AppTranslationGateway) => {
  return new AppTranslationService(gateway);
};

/**
 * Proveedor del servicio de traducción
 */
const getTranslationService = {
  provide: AppTranslationService,
  useFactory: getTranslationServiceFactory,
  deps: [AppTranslationGateway]
};

/**
 * Proveedor del servicio de traducción de ngx-translate
 */
const getNgxTranslateService = {
  provide: TranslateService,
  useClass: TranslateService
};

/**
 * DI de traducción
 */
export const getTranslationDi = [
  getNgxTranslateService,
  getTranslationServiceProvider,
  getTranslationService
];
