import { Observable } from 'rxjs';

import { UseCase } from '@domain-utils/use-case';
import { IdentityUser, IdentityRepository } from '@model/identity';

/**
 * Caso de uso para obtener la información del usuario
 */
export class GetIdentityUserUseCase implements UseCase<void, IdentityUser> {
  /**
   * Constructor del caso de uso
   * @param _identityRepository Repositorio de seguridad
   */
  constructor(private _identityRepository: IdentityRepository) {}

  /**
   * Método que ejecuta el caso de uso
   * @returns Observable con la información del usuario
   */
  execute(): Observable<IdentityUser> {
    return this._identityRepository.getUserInfo();
  }
}
