import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { IconDirective } from '@view/shared/atoms/icon/icon.directive';
import { OptionItem } from './option-item';

/**
 * Componente que representa un item de opcion.
 */
@Component({
  selector: 'app-option-item',
  imports: [CommonModule, IconDirective],
  template: `
    <div [class]="'flex max-w-fit ' + getIconPosition()">
      <div class="flex flex-row min-w-0 gap-x-4">
        <div class="min-w-0 self-center">
          <p class="text-left text-sm font-semibold m-0">
            {{ item().title }}
          </p>
          @if (item().subtitle) {
            <p class="text-left mt-1 text-xs">
              {{ item().subtitle }}
            </p>
          }
        </div>
      </div>
      <i appIcon [icon]="item().icon" class="self-center mx-1"></i>
    </div>
  `,
  styles: ``
})
export class OptionItemComponent {
  /** item de opción */
  item = input.required<OptionItem>();
  /** posición del icono */
  iconPosition = input('right');

  /**
   * Devuelve la posición del icono.
   * @returns posición del icono.
   */
  getIconPosition() {
    return this.iconPosition() === 'right' ? 'flex-row' : 'flex-row-reverse';
  }
}
