import { IdentityRepository } from '@model/identity';

/**
 * Caso de uso para cerrar la sesión del usuario
 */
export class FullLogoutUseCase {
  /**
   * Constructor del caso de uso
   * @param _identityRepository Repositorio de seguridad
   */
  constructor(private _identityRepository: IdentityRepository) {}

  /**
   * Método que ejecuta el caso de uso
   * @param fullUrlLogout URL de logout completo
   */
  execute(fullUrlLogout: string): void {
    this._identityRepository.fullLogout(fullUrlLogout);
  }
}
