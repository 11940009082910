import { from, Observable } from 'rxjs';

import { UseCase } from '@domain-utils/use-case';
import { IdentityRepository, Menu } from '@model/identity';

/**
 * Caso de uso para obtener el menú del usuario
 */
export class GetUserMenuUseCase implements UseCase<void, Menu[]> {
  /**
   *  Constructor del caso de uso
   * @param _identityRepository Repositorio de seguridad
   */
  constructor(private _identityRepository: IdentityRepository) {}

  /**
   * Método que ejecuta el caso de uso
   * @returns Observable con el menú del usuario
   */
  execute(): Observable<Menu[]> {
    return from(this._identityRepository.getUserMenu());
  }
}
