import {
  AuthorizationGuard,
  securityInterceptRequestService
} from '@adapter/security';
import { EnvUtils } from '@common/env-utils';

/**
 * Carga los Guards de seguridad
 * @returns Guards de seguridad
 */
const loadGuards = () => {
  if (EnvUtils.isSecureMock()) {
    return [];
  }

  return [AuthorizationGuard];
};

/**
 * Carga los Interceptors de seguridad
 * @returns Interceptors de seguridad
 */
const loadInterceptors = () => {
  if (EnvUtils.isSecureMock()) {
    return [];
  }

  return [securityInterceptRequestService];
};

/**
 * Exporta los loaders de seguridad
 */
export const SecurityLoaders = {
  loadGuards,
  loadInterceptors
};
