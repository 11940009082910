/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { providePrimeNG } from 'primeng/config';
import { SuraPreset } from './sura-preset';

/**
 * Configuración de PrimeNG
 */
export const primeConfig = [
  providePrimeNG({
    ripple: true,
    theme: {
      preset: SuraPreset,
      options: {
        darkModeSelector: false,
        cssLayer: {
          name: 'primeng',
          order: 'tailwind-base, primeng, tailwind-utilities'
        }
      }
    }
  })
];
