import { HttpClient } from '@angular/common/http';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { httpLoaderFactory } from './loader';

/**
 * Configuración de ngx-translate, aquí se establece el idioma por defecto y el loader ára
 * cargar las traducciones de los ficheros JSON que residen en assets/i18n.
 */
export const translationConfig = [
  provideTranslateService({
    defaultLanguage: 'es',
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpClient]
    }
  })
];
