import { inject, Injectable } from '@angular/core';
import { AppNotification } from '@service/notification/app-notification';
import { AppNotificationGateway } from '@service/notification/app-notification.gateway';
import { MessageService } from 'primeng/api';

/**
 * Implementación del servicio de notificación usando primeNG
 */
@Injectable({
  providedIn: 'root'
})
export class PrimeNotificationService extends AppNotificationGateway {
  /**
   * Servicio de mensajes de primeNG
   */
  private primeMessageService = inject(MessageService);

  /**
   * Método que se encarga de lanzar una notificación
   * @param notification  información de la notificación
   */
  notify(notification: AppNotification): void {
    this.primeMessageService.add({
      severity: notification.severity,
      summary: notification.title,
      detail: notification.message
    });
  }
}
