import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IdentityUser } from '@model/identity';
import { GetIdentityUserUseCase } from '@usecase/identity';
import { OptionItem } from '@view/shared/molecules/option-item/option-item';
import { OptionListComponent } from '@view/shared/organisms/option-list/option-list.component';
import { TopBarComponent } from '@view/shared/organisms/top-bar/top-bar.component';
import { getIdentityDi } from '@cfg/security/identity-di';
import { CommonModule } from '@angular/common';
import { IdentityOperations } from '@model/identity/operations';
import { RouteService } from '@helpers/routing/router.service';
import { AppTranslationService } from '@service/translation/app-translation.service';

/**
 * Componente que renderiza el layout de la aplicación
 */
@Component({
  selector: 'app-layout',
  imports: [RouterModule, CommonModule, TopBarComponent, OptionListComponent],
  providers: [getIdentityDi],
  template: `
    <div class="max-w-full">
      <section>
        <app-top-bar
          [username]="user.fullName"
          [lastAccess]="user.lastAccess"
          (menuStateChange)="onMenuStateChange($event)"
          (userMenuStateChange)="onUserMenuStateChange($event)"
        ></app-top-bar>
        <!-- userMenu -->
        <div class="relative">
          <div
            class="absolute right-0 w-52 bg-white app-shadow--basic app-animate-all--ease"
            [ngClass]="{
              'top-0 z-10 opacity-100': isUserMenuOpen(),
              'opacity-0 -top-10 -z-10': !isUserMenuOpen()
            }"
          >
            <app-option-list
              id="userMenu"
              [items]="userMenu"
              (itemClick)="onUserMenuItemClick($event)"
            ></app-option-list>
          </div>
        </div>
      </section>
      <div class="flex">
        <!-- sidebar -->
        <div
          class="fixed mr-6 min-h-full w-40 pt-2 app-shadow--basic app-animate-all--ease"
          [ngClass]="{
            'ml-0 opacity-100': isMenuOpen(),
            '-ml-80 opacity-0': !isMenuOpen()
          }"
        >
          <app-option-list
            id="appMenu"
            [items]="menuItems"
            [selectable]="true"
            (itemClick)="onMenuItemClicked($event)"
          ></app-option-list>
        </div>
        <!-- main -->
        <div
          class="w-full app-animate-all--ease"
          [ngClass]="{
            'ml-48': isMenuOpen(),
            'ml-0': !isMenuOpen()
          }"
        >
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `
})
export class LayoutComponent implements OnInit {
  /** caso de uso para obtener el usuario */
  usecase = inject(GetIdentityUserUseCase);
  translator = inject(AppTranslationService);
  router = inject(RouteService);

  /** opciones de menú del usuario */
  userMenu: OptionItem[] = [];

  user: IdentityUser = {} as IdentityUser;
  userMenuState = 'closed';
  menuItems: OptionItem[] = [];
  menuState = 'closed';

  /**
   * Inicialización del componente, se obtiene el usuario y se construye el menú
   */
  ngOnInit(): void {
    this.usecase.execute().subscribe((user) => {
      this.user = user;
      this.prepareAppMenu(user);
      this.prepareUserMenu(user);
    });
  }

  /**
   * Se ejecuta cuando el estado del menú cambia
   * @param event puede ser opened o closed
   */
  onMenuStateChange(event: string) {
    this.menuState = event;
  }

  /**
   * Se ejecuta cuando el estado del menú de usuario cambia
   * @param event puede ser opened o closed
   */
  onUserMenuStateChange(event: string) {
    this.userMenuState = event;
  }

  /**
   * Se ejecuta cuando se hace click en un item del menú de usuario
   * @param item item del menú
   */
  onUserMenuItemClick(item: OptionItem) {
    switch (item.id) {
      case 'logout':
        void this.router.redirectTo(item.path);
        break;
      default:
        console.warn('Opción de menú no soportada:', item);
    }
  }

  /**
   * Se ejecuta cuando se hace click en un item del menú sidebar
   * @param item item del menú
   */
  onMenuItemClicked(item: OptionItem) {
    void this.router.navigateTo(item.path);
    this.menuState = 'closed';
  }

  /**
   * Determina si el menú de usuario está abierto
   */
  isUserMenuOpen() {
    return this.userMenuState === 'opened';
  }

  isMenuOpen() {
    return this.menuState === 'opened';
  }

  /**
   * Construye el menú de la aplicación
   * @param {IdentityUser} user usuario
   */
  private prepareAppMenu(user: IdentityUser) {
    const appMenu = IdentityOperations.getMenu(user);
    this.menuItems = appMenu[0].menuItems.map((item) => {
      return {
        id: item.cdresource.toString(),
        path: `/${item.contextPath}`,
        title: item.name,
        icon: item.image,
        active: false
      };
    });
  }

  /**
   * Construye el menú de usuario
   * @param {IdentityUser} user usuario
   */
  private prepareUserMenu(user: IdentityUser) {
    this.userMenu.push({
      id: 'logout',
      path: user.fullUrlLogout,
      title: this.translator.get('security.logout'),
      icon: 'user'
    });
  }
}
