/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNG } from 'primeng/config';

/**
 * Inicializador para configurar ngx-translate en PrimeNG para la gestión de idioma.
 * @returns Promesa que se resuelve cuando se han cargado las traducciones.
 */
export const initializePrimeTranslation = () => {
  const primeNg = inject(PrimeNG);
  const translator = inject(TranslateService);

  return () => {
    return new Promise<void>((resolve) => {
      translator.get('primeng').subscribe((translations) => {
        console.log('translations', translations);
        primeNg.setTranslation(translations);
        resolve();
      });
    });
  };
};
