import { Component, ElementRef, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './shared/templates/layout/layout.component';
import { getConfirmationDi } from '@cfg/services/confirmation-di';
import { getNotificationDi } from '@cfg/services/notification-di';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { getTranslationDi } from '@cfg/i18n/translation-di';

/**
 * Componente inicial de la aplicación, aquí se carga el router-outlet
 */
@Component({
  selector: 'app-root',
  imports: [RouterModule, ToastModule, ConfirmDialogModule, LayoutComponent],
  providers: [getConfirmationDi, getNotificationDi, getTranslationDi],
  template: ` <app-layout></app-layout> <p-confirmDialog /> <p-toast />`,
  styles: []
})
export class AppComponent implements OnInit {
  /**
   * Crea una instancia de AppComponent.
   * @param _elementRef Referencia al elemento del componente.
   */
  constructor(private _elementRef: ElementRef) {}

  /**
   * Inicializa el componente y elimina el atributo ng-version del elemento
   */
  ngOnInit(): void {
    (this._elementRef.nativeElement as HTMLElement).removeAttribute(
      'ng-version'
    );
  }
}
