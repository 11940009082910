// import { IdentityServiceImpl } from '@adapter/security';
import { IdentityServiceImpl } from '@adapter/security';
import { IdentityRepositoryImplMock } from '@adapter/security/mocks/identity-service.mock';
import { IdentityRepository } from '@model/identity';
import {
  GetIdentityUserUseCase,
  GetUserMenuUseCase,
  FullLogoutUseCase
} from '@usecase/identity';
import { environment } from 'src/environments/environment';

/**
 * Identity DI
 */
const getIdentityRepositoryProvider = {
  provide: IdentityRepository,
  useClass:
    environment.useMocks || environment.identity.useMocks
      ? IdentityRepositoryImplMock
      : IdentityServiceImpl
};

/**
 * Factoria para el caso de uso de obtener el usuario de identidad
 * @param identityRepository repositorio de identidad
 * @returns implementación del caso de uso
 */
const getIdentityUserUseCaseFactory = (
  identityRepository: IdentityRepository
) => {
  return new GetIdentityUserUseCase(identityRepository);
};

/**
 * Factoria para el caso de uso de obtener el menú del usuario
 * @param identityRepository repositorio de identidad
 * @returns implementación del caso de uso
 */
const getUserMenuUseCaseFactory = (identityRepository: IdentityRepository) => {
  return new GetUserMenuUseCase(identityRepository);
};

/**
 * Factoria para el caso de uso de cerrar sesión
 * @param identityRepository repositorio de identidad
 * @returns implementación del caso de uso
 */
const fullLogoutUseCaseFactory = (identityRepository: IdentityRepository) => {
  return new FullLogoutUseCase(identityRepository);
};

/**
 * Proveedor del caso de uso de obtener el usuario de identidad
 */
const getIdentityUserUseCaseProvider = {
  provide: GetIdentityUserUseCase,
  useFactory: getIdentityUserUseCaseFactory,
  deps: [IdentityRepository]
};

/**
 * Proveedor del caso de uso de obtener el menú del usuario
 */
const getUserMenuUseCaseProvider = {
  provide: GetUserMenuUseCase,
  useFactory: getUserMenuUseCaseFactory,
  deps: [IdentityRepository]
};

/**
 * Proveedor del caso de uso de cerrar sesión
 */
const fullLogoutUseCaseProvider = {
  provide: FullLogoutUseCase,
  useFactory: fullLogoutUseCaseFactory,
  deps: [IdentityRepository]
};

/**
 * Exportación de los proveedores de dependencias
 */
export const getIdentityDi = [
  getIdentityRepositoryProvider,
  getIdentityUserUseCaseProvider,
  getUserMenuUseCaseProvider,
  fullLogoutUseCaseProvider
];
