import { Menu } from './menu';
import { IdentityUser } from './user';

/**
 * Obtiene el menú del usuario
 * @param {IdentityUser} user usuario
 * @returns {Menu[]} menú del usuario
 */
const getMenu = (user: IdentityUser): Menu[] => JSON.parse(user.menu) as Menu[];

/**
 * Operaciones de identidad
 */
export const IdentityOperations = {
  getMenu
};
