import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { AppTranslationService } from '@service/translation/app-translation.service';
import { IconDirective } from '@view/shared/atoms/icon/icon.directive';
import { OptionItem } from '@view/shared/molecules/option-item/option-item';
import { OptionItemComponent } from '@view/shared/molecules/option-item/option-item.component';

/**
 * Componente que representa la barra superior de la aplicación.
 */
@Component({
  selector: 'app-top-bar',
  imports: [IconDirective, OptionItemComponent],
  template: `
    <div class="h-16 w-full bg-primary text-center">
      <div class="flex h-full items-center justify-between">
        <div
          tabindex="0"
          class="flex h-16 w-16 cursor-pointer items-center justify-center hover:bg-blue-200"
          (click)="onMenuClicked()"
          (keyup.enter)="onMenuClicked()"
        >
          <i appIcon icon="align-justify" class="text-white"></i>
        </div>
        <div
          tabindex="1"
          class="flex h-16 cursor-pointer items-center justify-center px-4 hover:bg-blue-200"
          (click)="onUserMenuClicked()"
          (keyup.enter)="onUserMenuClicked()"
        >
          <app-option-item
            [item]="getUserItem()"
            class="text-white"
          ></app-option-item>
        </div>
      </div>
    </div>
  `,
  styles: ``
})
export class TopBarComponent {
  /** Nombre del usuario */
  username = input.required<string>();
  /** Último acceso del usuario */
  lastAccess = input.required<string>();

  private translatorService = inject(AppTranslationService);

  /**
   * Emite evento cuando el menú de opciones cambia de estado.
   */
  @Output() menuStateChange = new EventEmitter<string>();

  /**
   * Emite evento cuando el menú de usuario cambia de estado.
   */
  @Output() userMenuStateChange = new EventEmitter<string>();

  private optionMenuState = 'closed';
  private userMenuState = 'closed';

  /**
   * Construye un item con la información del usuario.
   * @returns item que representa al usuario.
   */
  getUserItem(): OptionItem {
    return {
      id: this.username(),
      path: '',
      title: this.username(),
      subtitle: this.getLastConnection(),
      icon: this.getIcon()
    };
  }

  /**
   * Obtiene la última conexión del usuario.
   */
  getLastConnection() {
    return `${this.translatorService.get('security.last-access', { lastAccess: this.lastAccess() })}`;
  }

  /**
   * Obtiene el icono que se muestra en el menú de usuario.
   */
  getIcon() {
    return this.userMenuState == 'opened' ? 'angle-up' : 'angle-down';
  }

  /**
   * Cambia el estado del menú de opciones.
   */
  onMenuClicked() {
    this.optionMenuState =
      this.optionMenuState === 'closed' ? 'opened' : 'closed';
    this.menuStateChange.emit(this.optionMenuState);
  }

  /**
   * Cambia el estado del menú de usuario.
   */
  onUserMenuClicked() {
    this.userMenuState = this.userMenuState === 'closed' ? 'opened' : 'closed';
    this.userMenuStateChange.emit(this.userMenuState);
  }
}
