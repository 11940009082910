/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { definePreset } from '@primeng/themes';
import Material from '@primeng/themes/material';

/**
 * Definición de estilos personalizados para el tema de PrimeNG
 */
export const SuraPreset = definePreset(Material, {
  primitive: {
    blue: {
      50: '#dfeaff',
      100: '#e5e9ea',
      200: '#81b1ff',
      300: '#93c5fd',
      400: '#8a9cd3',
      500: '#2d6df6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#0033a0',
      900: '#1e3a8a',
      950: '#172554'
    }
  },
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    }
  },
  components: {
    confirmdialog: {
      icon: {
        size: '2rem'
      }
    },
    tooltip: {
      max: {
        width: '350px'
      },
      background: 'white',
      color: '#374151'
    }
  }
});
