import { inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Inyección de dependencias para el objeto global window
 */
export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window
});

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  router = inject(Router);
  window = inject(WINDOW);

  /**
   * Navega a una ruta específica en la aplicación
   * @param {string} route ruta destino
   * @returns {void}
   */
  public navigateTo(route: string): void {
    void this.router.navigate([route]);
  }

  /**
   * Recarga la pagina en una ruta específica
   * @param {string} route ruta destino
   * @returns {void}
   */
  public redirectTo(route: string): void {
    this.window.location.href = route;
  }

  /**
   * Reemplaza el contenido de la página con el documento especificado
   * @param {string} doc documento
   * @returns {void}
   */
  public replaceWith(doc: string): void {
    this.window.document.write(doc);
  }
}
